import React from "react";

const MyPost = () => {
  return (
    <div>
      <h2>My All Post</h2>
    </div>
  );
};

export default MyPost;
