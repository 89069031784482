import React from "react";

const Review = () => {
  return (
    <div>
      <h2>Give Rivew</h2>
    </div>
  );
};

export default Review;
